import React from "react"
import LayoutA6 from "../../components/layout-a6"
import EnrollStep from "../../components/enroll-step"
import enrollStyles from "./a6_enroll.module.css"

export default class A6EnrollStep extends React.Component {
  render() {
    const color='#1a55a3'
    const src='/demos/themeA6/enroll'
    return(
      <LayoutA6 >
        <div className={enrollStyles.enrollContainer}>
          <EnrollStep color={color} src={src} />
        </div>
      </LayoutA6>
    )
  }
}